import { JobType } from 'src/types/Job';

type JobOption = {
    value: JobType;
    displayValue: string;
};

export function getJobTypeOptions(jobType: string): JobOption[] {
    const baseOption = {
        value: '' as JobType,
        displayValue: 'Please select...',
        disabled: true,
    };
    switch (jobType) {
        case JobType.COOKER:
            return [
                { ...baseOption },
                { value: JobType.COOKER_ELECTRIC, displayValue: 'Electric' },
                { value: JobType.COOKER_GAS, displayValue: 'Gas' },
                { value: JobType.COOKER_DUAL, displayValue: 'Dual' },
            ];
        case JobType.HOB:
            return [
                { ...baseOption },
                { value: JobType.HOB_ELECTRIC, displayValue: 'Electric' },
                { value: JobType.HOB_GAS, displayValue: 'Gas' },
            ];
        case JobType.OVEN:
            return [
                { ...baseOption },
                { value: JobType.OVEN_ELECTRIC, displayValue: 'Electric' },
                { value: JobType.OVEN_GAS, displayValue: 'Gas' },
            ];
        case JobType.TUMBLE_DRYER:
            return [
                { ...baseOption },
                { value: JobType.TUMBLE_DRYER_ELECTRIC, displayValue: 'Electric' },
                { value: JobType.TUMBLE_DRYER_GAS, displayValue: 'Gas' },
            ];
        default:
            return [{ ...baseOption }];
    }
}
