import { Icon } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import InputDropdown from 'src/components/InputDropdown';
import { Config } from 'src/features/newClaim/types/Question';
import { FormState } from '../../types/Form';
import { QuestionStageValues } from '../../types/Question';
import { mapIconsToAppliances } from '../../utils/mapIconsToAppliances';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    description?: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    error: string;
    config?: Config;
}

const DropdownCalendar = ({
    name,
    title,
    description,
    formState,
    setState,
    error,
    config,
}: Props) => {
    const [selectedYearOption, setSelectedYearOption] = useState({ id: null, name: null });
    const [selectedMonthOption, setSelectedMonthOption] = useState({ id: null, name: null });
    const [showError, setShowError] = useState(false);

    const generateArrayOfYears = () => {
        const max: number = Number(new Date().getFullYear());
        const min = max - 50;
        const years: { id: number; name: string }[] = [];

        // eslint-disable-next-line no-plusplus
        for (let i = max; i >= min && i <= max; i--) {
            years.push({ id: i, name: i.toString() });
        }

        return years;
    };

    const calenderData = [
        { id: '01', name: 'January' },
        { id: '02', name: 'February' },
        { id: '03', name: 'March' },
        { id: '04', name: 'April' },
        { id: '05', name: 'May' },
        { id: '06', name: 'June' },
        { id: '07', name: 'July' },
        { id: '08', name: 'August' },
        { id: '09', name: 'September' },
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' },
    ];

    const validateSelection = (month, year) => {
        const purchaseDate = `${year.name}-${month.id}`;
        return new Date(purchaseDate) < new Date();
    };
    const yearOptions = generateArrayOfYears();

    const handleMonthSelection = (option) => {
        if (selectedYearOption.id) {
            const isValid = validateSelection(option, selectedYearOption);
            setShowError(!isValid);
        }
        setSelectedMonthOption(option);
        setState({ [name]: option }); // appliancePurchaseMonth
    };

    const handleYearSelection = (option) => {
        if (selectedMonthOption.id) {
            const isValid = validateSelection(selectedMonthOption, option);
            setShowError(!isValid);
        }
        setSelectedYearOption(option);
        setState({ appliancePurchaseYear: option });
    };

    return (
        <>
            {formState.jobType && (
                <div className={style.applianceTitle}>
                    {config?.icon && (
                        <Icon
                            icon={mapIconsToAppliances(formState.jobType)}
                            size="l"
                            variant="primary"
                        />
                    )}

                    <legend className={style.title}>{title}</legend>
                </div>
            )}
            <legend className={style.title}>{description}</legend>
            <div className={style.inputAndError}>
                <div className={`${style.dropdownCalendarContainer} ${style.dropdownCalendar} `}>
                    <InputDropdown
                        customStyle={style.dropdownMonth}
                        options={calenderData}
                        selectedOption={selectedMonthOption}
                        setSelectedOption={handleMonthSelection}
                        config={config}
                    />
                    <InputDropdown
                        customStyle={style.dropdownYear}
                        options={yearOptions}
                        selectedOption={selectedYearOption}
                        setSelectedOption={handleYearSelection}
                        config={{ ...config, placeholder: 'Year' }}
                    />
                </div>
                {(error || showError) && (
                    <div
                        className={style.fnolformCalendar}
                        data-testid="validation-error"
                        role="alert"
                    >
                        {error || 'Invalid Date Selected'}
                    </div>
                )}
            </div>
        </>
    );
};

export default DropdownCalendar;
