import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';
import { checkIsAllowedJobType } from '../utils/checkIsAllowedJobType';
import { determineApplianceAge } from '../utils/determineContributionAmount';
import { hasOpenJobSameType } from '../utils/hasOpenJobSameType';

export const appliancesQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'appliancesGroup1',
            questions: [
                {
                    name: 'jobType',
                    title: 'Your appliance cover',
                    description: 'Please select the faulty appliance',
                    type: QuestionType.CATEGORIES,
                    isRequired: true,
                    data: _customerData,
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    jobType: values.jobType,
                    description: values.description,
                    applianceId: values.applianceId,
                } as Partial<FormState>;

                return formState;
            },
            options: [
                {
                    copy: 'Back',
                    nextQuestion: 'problemAreaNew',
                    variant: 'outlined',
                },
                {
                    copy: 'Next',
                    isDisabled: (values) => !values.jobType || !values.description,
                    nextQuestion: (values) => {
                        if (
                            typeof values.jobType === 'string' &&
                            hasOpenJobSameType(_customerData.jobs, values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }
                        return 'appliancesGroup2';
                    },
                },
            ],
        },
        {
            handle: 'appliancesGroup2',
            questions: [
                {
                    name: 'applianceMaker',
                    title: 'Repair Details',
                    description: 'What is the make of your appliance?',
                    type: QuestionType.DROPDOWN_SEARCH,
                    isRequired: true,
                    config: {
                        placeholder: 'Search...',
                        icon: true,
                    },
                },
                {
                    name: 'applianceModel',
                    description: 'What is the model of your appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: true,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'jobType',
                    description: 'What fuel does the appliance use?',
                    type: QuestionType.DROPDOWN_NEW,
                    isRequired: true,
                    config: {
                        placeholder: 'Please select an option',
                    },
                },
                {
                    name: 'appliancePurchaseMonth',
                    description: 'When did you purchase your appliance?',
                    type: QuestionType.DROPDOWN_CALENDAR,
                    isRequired: true,
                    config: {
                        placeholder: 'Month',
                    },
                },
                {
                    name: 'applianceFaultCode',
                    description: 'Is there any fault code on the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: false,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'applianceSerialNumber',
                    description: 'What is the serial number of the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: false,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
            ],
            onSubmit: (values: any) => {
                const purchaseDate = `${values.appliancePurchaseYear.name}-${
                    values.appliancePurchaseMonth.id ? values.appliancePurchaseMonth.id : ''
                }`;

                const calculatedApplianceAge = determineApplianceAge(purchaseDate);

                const newState: Partial<FormState> = {
                    applianceMaker: values.applianceMaker,
                    applianceModel: values.applianceModel,
                    applianceFaultCode: values.applianceFaultCode || '',
                    applianceSerialNumber: values.applianceSerialNumber || '',
                    appliancePurchaseDate: new Date(purchaseDate).toISOString(),
                    applianceAge: calculatedApplianceAge,
                };

                if (values.jobType) {
                    newState.jobType = values.jobType;
                }

                return newState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            typeof values.jobType === 'string' &&
                            hasOpenJobSameType(_customerData.jobs, values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }

                        return 'vulnerability';
                    },
                    isDisabled: (values: any, jobType?: string) => {
                        const purchaseDate = `${
                            values.appliancePurchaseYear?.name
                                ? values.appliancePurchaseYear.name
                                : ''
                        }-${
                            values.appliancePurchaseMonth?.id
                                ? values.appliancePurchaseMonth.id
                                : ''
                        }`;
                        return (
                            !values.applianceMaker ||
                            !values.applianceModel ||
                            !values.appliancePurchaseMonth ||
                            !values.appliancePurchaseYear ||
                            !checkIsAllowedJobType(values.jobType || jobType) ||
                            new Date(purchaseDate) > new Date()
                        );
                    },
                },
            ],
        },
    ];
};
