import { APPLIANCES_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { AppliancePayload } from 'src/types/Appliances';
import { getMandatoryEnvVar } from 'src/utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const updateAppliance = async (data: AppliancePayload) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    try {
        const response = await fetch(
            `${API_BASE_URL}/${APPLIANCES_ENDPOINT}/v1/appliances/update`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            }
        );

        // eslint-disable-next-line @typescript-eslint/return-await
        return response.json();
    } catch (err) {
        const errorMessage = `Failed to update appliance - ${err.message}`;
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        throw new Error(errorMessage);
    }
};
