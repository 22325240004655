import { Dropdown } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import { Config } from 'src/features/newClaim/types/Question';
import { JobType } from 'src/types/Job';
import { FormState } from '../../types/Form';
import { QuestionStageValues } from '../../types/Question';
import { checkIsAllowedJobType } from '../../utils/checkIsAllowedJobType';
import { getJobTypeOptions } from './getJobTypeOptions';
import styles from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    error: string;
    config?: Config;
}

const DropdownNewQuestion = ({ name, title, formState, setState, error, config }: Props) => {
    const [selectedFuelOption, setSelectedFuelOption] = useState('');

    const handleFuelSelection = (option) => {
        setSelectedFuelOption(option);
        setState({ [name]: option });
    };

    return (
        <>
            {formState.jobType && !checkIsAllowedJobType(formState.jobType as JobType) ? (
                <div className={styles.inputAndError}>
                    <Dropdown
                        id={name}
                        label={title}
                        customStyle={styles.dropdown}
                        options={getJobTypeOptions(formState.jobType)}
                        selectedOption={selectedFuelOption}
                        onSelectedOptionChange={handleFuelSelection}
                        testId="booking-tool-dropdown"
                        error={error || ''}
                        placeholder={config?.placeholder}
                    />
                </div>
            ) : null}
        </>
    );
};

export default DropdownNewQuestion;
