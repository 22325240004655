import { Attention } from 'src/images';
import styles from './index.module.scss';

interface FailedProps {
    title?: string;
    extraCopy?: JSX.Element;
    rejectProduct?: string | undefined;
    otherTitle?: string;
}

function lowerCaseFirstLetter(string: string): string {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function isRejectionProductPlural(string: string): boolean {
    return (
        string.includes(' and ') ||
        string.endsWith('s') ||
        string.startsWith('leaks that are not visible')
    );
}

const Failed = ({
    title = 'Error!',
    extraCopy = undefined,
    rejectProduct,
    otherTitle,
}: FailedProps) => {
    const rejectionProductPlural = isRejectionProductPlural(rejectProduct || '')
        ? 'are not'
        : 'is not';

    return (
        <div className={styles.failedContainer}>
            <Attention className={styles.icon} />
            {rejectProduct && (
                <span className={styles.title}>
                    Unfortunately, {lowerCaseFirstLetter(rejectProduct)} {rejectionProductPlural}{' '}
                    covered under any of our policies.
                </span>
            )}
            {title !== '' && <h5>{title}</h5>}
            {otherTitle ? <p>{otherTitle}</p> : null}
            {extraCopy && <div className={styles.extraCopy}>{extraCopy}</div>}
        </div>
    );
};

export default Failed;
