import { JobType } from 'src/types/Job';

export const checkIsAllowedJobType = (jobType: JobType) => {
    switch (jobType) {
        case JobType.COOKER:
        case JobType.HOB:
        case JobType.OVEN:
        case JobType.TUMBLE_DRYER:
            return false;
        default:
            return true;
    }
};
