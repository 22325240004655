import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from 'src/components/Button';
import { FormState } from 'src/features/closeJob/types/Form';
import { AttentionGradient } from 'src/images';
import { JobDetails } from 'src/types/Job';
import { Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title?: string;
    options: Option[];
    onSubmit: (value: string, nextQuestion?: string) => void;
    jobDetails: JobDetails;
    formState: Partial<FormState>;
}

let refetchJobDetailsInterval;

const CostAuthorisation = ({
    title = 'Call Hometree now to confirm authorisation',
    options,
    onSubmit,
    jobDetails,
    formState,
}: Props) => {
    const [disableButton, setDisableButton] = useState<string | null>(null);
    const queryClient = useQueryClient();

    useEffect(() => {
        setDisableButton(jobDetails.sk_authorise_follow_on);
    }, [jobDetails.sk_authorise_follow_on]);

    useEffect(() => {
        clearInterval(refetchJobDetailsInterval);

        refetchJobDetailsInterval = setInterval(() => {
            queryClient.refetchQueries('jobDetails');
        }, 5000);
    }, [queryClient]);

    return (
        <div className={style.container}>
            <AttentionGradient className={style.attention} />
            <h4 className={style.title}>{title}</h4>
            <div className={style.extraCopy}>
                <a href="tel:03300589485">0330 058 9485</a>
            </div>
            <div className={style.reminder}>
                You might not receive payment without a confirmed authorisation from Hometree.
                <br />
                Please contact us ASAP.
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        isDisabled={disableButton === null}
                        key={value}
                        onClick={() => {
                            clearInterval(refetchJobDetailsInterval);

                            onSubmit(
                                '',
                                nextQuestionSelector
                                    ? nextQuestionSelector('', formState)
                                    : nextQuestion
                            );
                        }}
                    >
                        <>{value}</>
                    </Button>
                );
            })}
        </div>
    );
};

export default CostAuthorisation;
